import CatFooter from "../js/CatFooter"
import cx from "classnames"
import "../css/NotFound.css"

export default function NotFound() {  
  return (
    <>
      <div className="not-found-container">
        <div className="not-found-message">
          There's nothing here!
          <div className="paw-prints">
            <CatPaw className="paw-01" />
            <CatPaw className="paw-02"/>
            <CatPaw className="paw-03"/>
          </div>
        </div>
      </div>
      <CatFooter 
        nextText="Home"
        nextLink="/"  
        />
    </>
  )
}

function CatPaw(props) {
  return(
    <div className={cx("paw", props.className)} >
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 29.33 24.79" > 
        <path d="m22.73,4.62c-0.57,2 -2.78,4.14 -4.73,3.64s-2.89,-3.42 -2.32,-5.42s2.61,-3.22 4.56,-2.73s3.06,2.51 2.49,4.52l0,-0.01z" />
        <path d="m28.84,12.32c-1.01,1.75 -3.73,3.19 -5.48,2.18s-1.86,-4.09 -0.85,-5.84c1.01,-1.75 3.24,-2.35 4.99,-1.34s2.35,3.24 1.34,4.99l0,0.01z"/>
        <path d="m6.82,8.67c1.01,1.75 0.9,4.83 -0.85,5.84s-4.47,-0.44 -5.48,-2.18s-0.41,-3.98 1.34,-4.99s3.98,-0.41 4.99,1.34l0,-0.01z" />
        <path d="m13.57,2.85c0.57,2 -0.37,4.93 -2.32,5.42s-4.16,-1.64 -4.73,-3.64s0.54,-4.03 2.49,-4.52s3.99,0.74 4.56,2.74z" />
        <path d="m14.66,9.43c-2.64,0 -3.76,1.7 -4.83,3.94c-1.37,2.87 -5.63,4.37 -4.94,8.27c0.91,5.1 5.92,2.44 9.76,2.39c3.84,0.11 8.85,2.71 9.76,-2.39c0.7,-3.9 -3.57,-5.41 -4.94,-8.27c-1.07,-2.24 -2.18,-3.94 -4.83,-3.94" />
      </svg>
    </div>
  )
}