import { useEffect, useRef, useState } from "react"
import { Col, Img, Row, WorkItem } from "../js/WorkContainer"
import CatFooter from "../js/CatFooter"
import video_01 from "../video/mck/digital-exp-01.mp4"
import image_01 from "../images/work/mck/digital-exp-01.png"
import image_02 from "../images/work/mck/digital-exp-02.png"
import image_03 from "../images/work/mck/digital-exp-03.png"
import image_04 from "../images/work/mck/digital-exp-04.png"
import image_05 from "../images/work/mck/digital-exp-05.png"
import image_06 from "../images/work/mck/voice-02.png"
import image_07 from "../images/work/mck/brochure-02.png"
import image_08 from "../images/work/mck/brochure-03.png"
import image_09 from "../images/work/mck/brochure-06.png"
import image_10 from "../images/work/mck/trifold-01.png"
import image_11 from "../images/work/mck/trifold-07.png"
import image_12 from "../images/work/mck/trifold-09.png"
import image_13 from "../images/work/mck/csr-01_cropped.png"
import image_14 from "../images/work/mck/csr-03.png"
import image_15 from "../images/work/mck/csr-04.png"
import image_16 from "../images/work/mck/mck-nav-06_cropped.png"
import image_17 from "../images/work/mck/marketo-desktop.png"
import image_18 from "../images/work/mck/marketo-mobile.png"
import image_19 from "../images/work/mck/pclub-event-reg-site-01.png"
import image_20a from '../images/work/mck/pclub-booklet-layer-01.png'
import image_20b from '../images/work/mck/pclub-booklet-layer-02.png'
import image_20c from '../images/work/mck/pclub-booklet-layer-03.png'
import image_21 from '../images/work/mck/pclub-booklet-mockup-03.png'
import image_22 from '../images/work/mck/powerpoint-04.png'
import image_23 from '../images/work/mck/powerpoint-03.png'
import image_24 from '../images/work/mck/powerpoint-06.png'
import image_25 from '../images/work/mck/swag-01.png'
import image_26 from '../images/work/mck/swag-02.png'
import image_27 from '../images/work/mck/swag-03.png'

export default function WorkMck() {

  const [windowWidth, setWindowWidth] = useState(getWindowWidth());
  const [mcKPclubEventSiteHeight, setMcKPclubEventSiteHeight] = useState(0);
  const [mckNavHeight, setMckNavHeight] = useState(0);
  const mckNavRef = useRef(null);
  const mcKPclubEventSiteRef = useRef(null);

  let delayCount = 1;

  useEffect(() => {

    observeMckNav();
    observeMckPclubEventSite();

    function handleResize() {
      setWindowWidth(getWindowWidth());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);

  }, [])

  function getWindowWidth() {
    const { innerWidth: width } = window;
    return width;
  }

  function observeMckNav() {
    const observer = new ResizeObserver(entries => {
      setMckNavHeight(entries[0].contentRect.height);
    })
    observer.observe(mckNavRef.current)
    return () => mckNavRef.current && observer.unobserve(mckNavRef.current)
  }

  function observeMckPclubEventSite() {
    const observer = new ResizeObserver(entries => {
      setMcKPclubEventSiteHeight(entries[0].contentRect.height);
    })
    observer.observe(mcKPclubEventSiteRef.current)
    return () => mcKPclubEventSiteRef.current && observer.unobserve(mcKPclubEventSiteRef.current)
  }
  
  return (
    <>
      <div className="work-content">
        <Row>
          <Col>
            <WorkItem title="McKesson Virtual Vision Center">
              <Row>
                <Col width="56-6">
                  <video autoPlay loop muted poster={image_01}>
                    <source src={video_01} type="video/mp4"/>
                    <a href="https://vimeo.com/343534374">McKesson Virtual Vision Center Preview Video</a>
                  </video>
                </Col>
                <Col width="41-4">
                  <Row doNotCollapse>
                    <Img src={image_02} alt="Retail pharmacy interactive experience" />
                    <Img src={image_04} alt="Interactive history timeline" />
                  </Row>
                  <Row doNotCollapse>
                    <Img src={image_03} alt="Retail pharmacy pop-up screen" />
                    <Img src={image_05} alt="Timeline event pop-up screen" />
                  </Row>
                </Col>
              </Row>
            </WorkItem>
          </Col>
        </Row>
        <Row>
          <Col width="34-9">
            <WorkItem title="McKesson Voice Guidelines" topBorder delay={delayCount++}>
              <Img src={image_06} alt="Mckesson Voice Guidelines booklet" constrainMobileHeight align="center"
              />
            </WorkItem>
          </Col>
          <Col width="63-5">
            <WorkItem title="McKesson Brand Refresh" topBorder delay={delayCount++}>
            <Col>
              <Row doNotCollapse>
                <Img src={image_09} alt="McKesson brochure cover template 1" />
                <Img src={image_07} alt="McKesson brochure interior layout template" border/>
                <Img src={image_08} alt="McKesson brochure cover template 2" />
              </Row>
              <Row>
                <Col>
                  <Img src={image_10} alt="McKesson tri-fold template exterior layout" border/>
                </Col>
                <Col rowOnMobile>
                  <Img src={image_11} alt="McKesson tri-fold template interior layout option 1" border/>
                  <Img src={image_12} alt="McKesson tri-fold template exterior layout option 2" border/>
                </Col>
              </Row>
            </Col>
            </WorkItem>
          </Col>
        </Row>
        <Row>
          <Col>
            <WorkItem title="FY18 Corporate Responsibilities Report" topBorder delay={delayCount++}>
              <Row>
                  <Img src={image_13} alt="McKesson Fiscal 2018 Corporate Responsibility Report cover image"/>
                  <Row doNotCollapse id="csr-pages">
                    <Img src={image_14} alt="Page with infographic on eco-efficient operations and transportation from McKesson Fiscal 2018 Corporate Responsibility Report" border/>
                    <Img src={image_15} alt="Page with infographic on employee demographics from McKesson Fiscal 2018 Corporate Responsibility Report" border/>
                  </Row>
              </Row>
            </WorkItem>
          </Col>
        </Row>
        <Row>
          <Col width="63-5">
            <WorkItem title="McKesson.com Navigation Bar" topBorder delay={delayCount++}>
              <Img src={image_16} alt="Prototype of user interaction flow for McKesson.com's navigation bar" align="top" ref={mckNavRef} />
            </WorkItem>
          </Col>
          <Col width="34-9">
            <WorkItem title="Marketo Email Templates" topBorder delay={delayCount++}>
              <Row>
                <Col width="56-9">
                  <Img src={image_17} alt="Desktop view of McKesson email newsletter" align="top" scrollable border
                    style={{
                      height: windowWidth < 1000 ? 500 : mckNavHeight,
                    }}
                  />
                </Col>
                <Col width="37-4">
                  <Img src={image_18} alt="Mobile view of McKesson email newsletter" align="top" scrollable border 
                    style={{
                      height: windowWidth < 1000 ? 500 : mckNavHeight,
                    }}
                  />
                </Col>
              </Row>
            </WorkItem>
          </Col>
        </Row>
        <Row>
          <Col width="65-8">
            <WorkItem title="President's Club" topBorder delay={delayCount++}>
              <Row>
                <Col width="52-7">        
                  <Img src={image_19} alt="President's club event registration landing page" ref={mcKPclubEventSiteRef} border constrainMobileHeight align="top"/>
                </Col>
                <Col width="44" justifyBottom 
                  style={{
                      height: windowWidth < 1000 ? "unset" : mcKPclubEventSiteHeight,
                  }}>
                  <div className="stacked-container">
                    <Img src={image_20a} alt="Table of contents shown on first page President Club 2019 event booklet" />  
                    <div id="pclub-border" className="border stacked"/>
                    <Img id="pclub-insert" src={image_20b} alt="Emergency contact sheet insert for President Club 2019 event booklet" stacked />  
                    <Img id="pclub-pocket" src={image_20c} alt="Insert pocket on the inside cover of President Club 2019 event booklet" stacked />  
                  </div>
                  <Img src={image_21} alt="Interior page layout from President Club 2019 event booklet" border/>  
                </Col>
              </Row>
            </WorkItem>
          </Col>
          <Col width="32-2">
            <WorkItem title="Enterprise PowerPoints" topBorder delay={delayCount++}>
              <Col>
                <Img src={image_22} alt="Powerpoint slide on strategic growth initiative" border />  
                <Img src={image_23} alt="Powerpoint slide on leadership drivers" border />  
                <Img src={image_24} alt="Powerpoint slide on McKesson 2021 goals" border /> 
              </Col>
            </WorkItem>
          </Col>
        </Row>
        <Row>
          <Col>
            <WorkItem title="McKesson Brand Swag" topBorder delay={delayCount++}>
              <Row>
                <Img src={image_25} alt="McKesson branded socks, design 1"/>
                <Img src={image_26} alt="McKesson branded beach towel"/>
                <Img src={image_27} alt="McKesson branded socks, design 2"/>
              </Row>
            </WorkItem>
          </Col>
        </Row>
      </div>
      <CatFooter 
        delay={delayCount++}
        prevText="Meta"
        prevLink="/work/fb"  
        nextText="Viz"
        nextLink="/work/viz"  
        />
    </>
  );
}
