import React from 'react'
import { Link } from "react-router-dom"
import { Col, Row, Img } from "../js/WorkContainer"
import "../css/Home.css"
import image_01 from "../images/work/logo512.png"

export default function Home() {
  let delayCount = 1;

  return (
    <div className="home-content"> 
      <Row>
        <Col width={100}>
          <Link className="work-tile" id="fb" to="/work/fb"
            style={{
              animationDelay: (delayCount++) +"00ms"
            }}>
            <MetaLogo/>
          </Link>
        </Col>
        <Col width={100}>
          <Link className="work-tile" id="mck" to="/work/mck" 
            style={{
              animationDelay: (delayCount++) +"00ms"
            }}>
            <McKLogo/>
          </Link>
        </Col>
        <Col width={100}>
          <Link className="work-tile" id="viz" to="/work/viz" 
            style={{
              animationDelay: (delayCount++)+"00ms"
            }}>
            <VizLogo/>
          </Link>
        </Col>
      </Row>
      <Row>
        <Col width={100}>
          <Link className="work-tile" id="kp" to="/work/kp"
            style={{
              animationDelay: (delayCount++) +"00ms"
            }}>
            <KPLogo/>
          </Link>
        </Col>
        <Col width={100}>
          <Link className="work-tile" id="archive" to="/work/archive"
            style={{
              animationDelay: (delayCount++) +"00ms"
            }}>
            <Img src={image_01} alt="Archive" />
          </Link>
        </Col>
        <Col width={100} empty></Col>
      </Row>
    </div>
  )
}


function McKLogo() {
  return (
    <svg version="1.2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1581 891" >
      <title>McKesson Work</title>
      <path fill="#ef8200" d="m1083.2 732.8h474v157.8h-474z"/>
      <path fill="#ffffff" d="m627.4 17.3h278.3v873.3h-194.5v-598.6c-30.9 100.3-189.5 598.6-189.5 598.6h-146.9c0 0-150.3-493.3-181.2-596.4v596.7h-193.6v-873.6h278.3c0 0 157.1 483.1 173.8 534 16.4-50.6 174.1-534 174.1-534m752 623.9c-209.3 0-342.3-119.5-342.3-319.9 0-200.3 133-320.4 342.3-320.4 17.4-0.3 34.7 0.4 52 2 17.3 1.5 34.5 4 51.5 7.5 17 3.4 33.8 7.7 50.4 13 16.5 5.2 32.8 11.3 48.6 18.3l-60.3 140.7q-11.7-6.9-24.3-12.3-12.6-5.3-25.8-8.9-13.2-3.6-26.8-5.4-13.6-1.8-27.3-1.8c-102.6 0-187.4 51-187.4 167.7 0 116.6 84.8 167.6 187.4 167.6 9.2 0 18.3-0.6 27.3-1.8 9.1-1.3 18-3.1 26.8-5.5 8.8-2.5 17.5-5.5 25.8-9.2 8.4-3.6 16.5-7.8 24.3-12.5l60.3 141c-15.8 7-32.1 13.1-48.6 18.3-16.6 5.2-33.4 9.5-50.4 12.9-17 3.4-34.2 5.8-51.5 7.3-17.3 1.5-34.7 2-52 1.7"/>
    </svg>
  )
}

function MetaLogo() {
  return(
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1505 1000">
      <title>Meta Work</title>
      <path fill="#ffffff" d="m1080,0c-123.39,0 -219.85,92.94 -307.16,210.99c-119.99,-152.77 -220.34,-210.99 -340.43,-210.99c-244.82,0 -432.41,318.62 -432.41,655.86c0,211.04 102.1,344.14 273.1,344.14c123.08,0 211.6,-58.03 368.97,-333.1c0,0 65.6,-115.84 110.72,-195.64q23.72,38.3 49.97,82.53l73.79,124.14c143.75,240.55 223.84,322.07 368.97,322.07c166.59,0 259.31,-134.92 259.31,-350.34c0,-353.11 -191.82,-649.66 -424.83,-649.66zm-557.93,592.41c-127.59,200 -171.73,244.83 -242.76,244.83c-73.1,0 -116.55,-64.18 -116.55,-178.62c0,-244.83 122.07,-495.17 267.58,-495.17c78.81,0 144.66,45.51 245.53,189.91c-95.78,146.91 -153.8,239.05 -153.8,239.05zm481.53,-25.17l-88.23,-147.15q-35.82,-58.25 -68.84,-107.23c79.52,-122.74 145.11,-183.89 223.12,-183.89c162.07,0 291.73,238.62 291.73,531.72c0,111.72 -36.59,176.55 -112.41,176.55c-72.68,0 -107.39,-47.99 -245.37,-270z"/>
    </svg>
  )
}

function VizLogo() {
  return(
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128.6 50.54">
      <title>Viz Media Work</title>
      <path fill="#ffffff" d="m41.61,50.3c-8.74,-1.04 -17.16,-4.59 -24.11,-10.17c-2.13,-1.71 -5.33,-4.93 -7.14,-7.19c-4.82,-6.03 -7.99,-12.8 -9.49,-20.31c-0.65,-3.24 -0.87,-5.62 -0.87,-9.33l0,-3.3l8.11,0l8.11,0l0.08,3.16c0.1,3.51 0.33,5.29 1.05,8.1c2.25,8.79 8.35,16.67 15.8,20.42l1.12,0.57l0,-16.12l0,-16.12l8.06,0l8.06,0l0,25.27l0,25.27l-3.52,-0.02c-2.03,-0.01 -4.26,-0.1 -5.27,-0.22l0.01,-0.01zm16.63,-25.03l0,-25.27l7.77,0l7.77,0l0,25.27l0,25.27l-7.77,0l-7.77,0l0,-25.27zm21.93,23.56c0,-7.13 1.62,-13.73 4.98,-20.3c2.68,-5.23 6.25,-9.5 10.26,-12.26l1.08,-0.74l-7.65,0l-7.65,0l0,-7.77l0,-7.77l23.67,0l23.67,0l0,8.04l0,8.04l-2,0.09c-5.27,0.23 -10.9,2.44 -15.94,6.25c-2.36,1.78 -5.22,4.93 -6.73,7.4c-1.03,1.69 -2.34,4.51 -2.34,5.05c0,0.09 4.81,0.14 13.5,0.14l13.51,0l0,7.77l0,7.77l-24.18,0l-24.18,0l0,-1.71z"/>
    </svg>
  ) 
}

function KPLogo() {
 return(
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 57.5 53.5">
      <title>Layer 1</title>
      <path fill="#ffffff" d="m36.21,16.3c-0.6,-0.5 -1.4,-1.1 -2.2,-1.3l-1.3,25.4l6.4,-17.6c0.6,-2.7 -0.9,-4.9 -2.9,-6.5" id="Fill-1"/>
      <path fill="#ffffff" d="m25.71,14.7l3.7,25.3l3.6,-25.3c-2.1,-0.8 -4.9,-0.9 -7.3,0" id="Fill-2"/>
      <path fill="#ffffff" d="m22.61,16.3c-2,1.7 -3.5,3.9 -2.9,6.5l6.4,17.6l-1.3,-25.4c-0.8,0.2 -1.6,0.7 -2.2,1.3" id="Fill-3"/>
      <path fill="#ffffff" d="m9.81,18.7c4.2,7.4 8.6,15.3 12.8,22.4c-1.5,-6.2 -3,-16.5 -5.2,-19.4c-2.5,-3.5 -7.6,-3 -7.6,-3" id="Fill-4"/>
      <path fill="#ffffff" d="m2.31,22.4l17.4,20l-10.8,-23.6c-2.8,0.1 -5.2,1.5 -6.6,3.6" id="Fill-5"/>
      <path fill="#ffffff" d="m38.61,27.8l-2.9,13.7c3.7,-6.4 7.4,-12.7 11,-19.1c-3.4,0.2 -6.9,1.5 -8.1,5.4" id="Fill-6"/>
      <path fill="#ffffff" d="m47.71,22.4l-9.2,20.3l16.1,-17.5c-1.8,-1.9 -4.2,-2.8 -6.9,-2.8" id="Fill-7"/>
      <path fill="#ffffff" d="m0.01,31.4c5.7,4.3 11.3,8.8 17,13.1l-15.3,-21.5c-1.4,2.5 -1.8,5.3 -1.7,8.4" id="Fill-8"/>
      <path fill="#ffffff" d="m56.61,27.7c-0.3,-0.7 -0.7,-1.3 -1.3,-1.8l-13.9,18.9c5.5,-4.1 10.8,-8.3 16.1,-12.4c0.1,-1.7 -0.1,-3.3 -0.9,-4.7" id="Fill-9"/>
      <path fill="#ffffff" d="m0.01,40.2l14.8,6.8c-4.9,-4.9 -9.9,-9.6 -14.8,-14.4l0,7.6" id="Fill-10"/>
      <path fill="#ffffff" d="m57.61,40.7l0,-6.9l-14,13.5c4.8,-2.3 9.2,-4.4 14,-6.6" id="Fill-11"/>
      <path fill="#ffffff" d="m0.01,46.9c4.5,1 9.3,2.2 13.3,3.1l-13.3,-8.8l0,5.7" id="Fill-12"/>
      <path fill="#ffffff" d="m57.61,47.3l0,-5.3c-4.2,2.6 -8.5,5.4 -12.4,8.1l12.4,-2.8" id="Fill-13"/>
      <path fill="#ffffff" d="m0.01,53.5l13,0l-13,-5.5l0,5.5" id="Fill-14"/>
      <path fill="#ffffff" d="m57.61,53.5l0,-5c-4.3,1.8 -8,3.3 -12,5l12,0" id="Fill-15"/>
      <path fill="#ffffff" d="m53.11,15.6c0.1,-7.8 -11.3,-7.8 -11.1,-0.1c-0.1,7.2 11.4,7.6 11.1,0.1" id="Fill-16"/>
      <path fill="#ffffff" d="m53.11,15.6c0,0 0,0 0,0l0,0l0,0" id="Fill-17"/>
      <path fill="#ffffff" d="m15.91,11.6c0.4,-7.8 -11.8,-7.5 -11.3,0c-0.4,7.8 12,7.9 11.3,0" id="Fill-18"/>
      <path fill="#ffffff" d="m29.41,13.1c4.5,0.1 6.3,-3.2 6.4,-6.3c0.2,-4.8 -2.8,-6.8 -6.4,-6.8c-3.6,0 -6.5,2.1 -6.3,6.8c0.1,2.7 1.2,6.2 6.3,6.3" id="Fill-19"/>
  </svg>
 )
}