import { useEffect, useState, useRef } from "react"
import { Col, Img, Row, WorkItem } from "../js/WorkContainer"
import CatFooter from "../js/CatFooter"
import image_00 from "../images/work/kp/tools-00-template.png"
import image_01 from "../images/work/kp/tools-01.png"
import image_02 from "../images/work/kp/tools-02.png"
import image_03 from "../images/work/kp/tools-03.png"
import image_04 from "../images/work/kp/tools-04.png"
import image_05 from "../images/work/kp/tools-05.png"
import image_06 from "../images/work/kp/search-01.png"
import image_07 from "../images/work/kp/mykp-home-01.png"

export default function WorkViz() {
  const [windowWidth, setWindowWidth] = useState(getWindowWidth());
  const [tools01Height, setTools01Height] = useState(0);
  const [tools02Height, setTools02Height] = useState(0);
  const [home01Height, setHome01Height] = useState(0);
  const tools01Ref = useRef(null);
  const tools02Ref = useRef(null);
  const home01Ref = useRef(null);

  let delayCount = 1;

  useEffect(() => {
    observeTools01();
    observeTools02();
    observeHome01();

    function handleResize() {
      setWindowWidth(getWindowWidth());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);

  }, [])

  function getWindowWidth() {
    const { innerWidth: width } = window;
    return width;
  }

  function observeTools01() {
    const observer = new ResizeObserver(entries => {
      setTools01Height(entries[0].contentRect.height);
    })
    observer.observe(tools01Ref.current)
    return () => tools01Ref.current && observer.unobserve(tools01Ref.current)
  }

  function observeTools02() {
    const observer = new ResizeObserver(entries => {
      setTools02Height(entries[0].contentRect.height);
    })
    observer.observe(tools02Ref.current)
    return () => tools02Ref.current && observer.unobserve(tools02Ref.current)
  }

  function observeHome01() {
    const observer = new ResizeObserver(entries => {
      setHome01Height(entries[0].contentRect.height);
    })
    observer.observe(home01Ref.current)
    return () => home01Ref.current && observer.unobserve(home01Ref.current)
  }

  return(
    <>
      <div className="work-content">
        <Row>
          <Col>
            <WorkItem title="Tools and Resources Redesign">
              <Row>
                <Col width="53-6" id="tools-page-01" style={{height: tools01Height}}>
                  <Img src={image_00} alt="All tools page navigation" />
                  <Img src={image_01} alt="All tools page content" ref={tools01Ref}/>
                </Col>
                <Col width="43-6">
                  <div id="tools-page-02" style={{height: tools02Height}}>
                    <Img src={image_00} alt="Suggested tools page navigation" />
                    <Img src={image_02} alt="Suggested tools page" ref={tools02Ref}/>  
                  </div>
                  <Row doNotCollapse
                    style={{height: windowWidth < 1000 ? tools02Height*0.75 : tools01Height - tools02Height - 16}}>
                    <Img src={image_03} alt="Suggested tools page on mobile" border scrollable align="top" />
                    <Img src={image_04} alt="All tools page on mobile" border scrollable align="top" />
                    <Img src={image_05} alt="Tools filter on mobile" border scrollable align="top" />
                  </Row>
                </Col>
              </Row>
            </WorkItem>
          </Col>
        </Row>
        <Row>
          <Col width="40">
            <WorkItem title="Search Redesign" topBorder delay={delayCount++}>
              <Img src={image_06} alt="New search results page design" border scrollable align="top" style={{maxHeight: home01Height}}/>
            </WorkItem>
          </Col>
          <Col width="60">
            <WorkItem title="MyKP Home Page Redesign" topBorder delay={delayCount++}>
                <Img src={image_07} alt="MyKP home page redesign, launch concept" border ref={home01Ref}/>
            </WorkItem>
          </Col>
        </Row>
      </div>
      <CatFooter 
        delay={delayCount++}
        prevText="Viz"
        prevLink="/work/viz"  
        nextText="Archive"
        nextLink="/work/archive"  
        />
    </>
  )
}