import { Link } from "react-router-dom"
import { useState } from "react"
import '../css/CatFooter.css'


export default function CatFooter(props) {

  const [lookDirection, setLookDirection] = useState(undefined);

  function lookLeft() {
    setLookDirection("left");
  }

  function lookRight() {
    setLookDirection("right")
  }

  function lookStraight() {
    setLookDirection(null)
  }

  function getPrevLink() {
    if(!props.prevLink) {
      return(
        <button className="back-button" type="button" 
          onClick={()=>{window.history.back()}}
          onMouseEnter={lookLeft} 
          onFocus={lookLeft} 
          onMouseLeave={lookStraight} 
          onBlur={lookStraight}
          >
          &#171; Back
        </button>
      )
    }
    return (
      <Link to={props.prevLink} onMouseEnter={lookLeft} onFocus={lookLeft} onMouseLeave={lookStraight} onBlur={lookStraight}>
      &#171; {props.prevText}
      </Link>
    )
  }

  return(
    <div className='cat-footer' style={{
      animationDelay: `${props.delay}00ms`
    }}>
      <div className="cat-footer-link prev-link">
        {getPrevLink()}
      </div>
      <Cat look={lookDirection}/>
      <div className="cat-footer-link next-link">
        <Link to={props.nextLink} onMouseEnter={lookRight} onFocus={lookRight} onMouseLeave={lookStraight} onBlur={lookStraight}>
          {props.nextText} &#187;
        </Link>
      </div>
    </div>
  )
}

function Cat(props) {
  return(
    <div className='cat-container'>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1225 620">
        <g id="Face">
          <path d="m1137.89,58.06c4.89-36.02-29.66-64.71-64.17-53.26l-229.11,75.96c-9.95,3.3-20.7,3.33-30.66.08-61.73-20.14-129.86-31.32-201.46-31.32s-139.72,11.18-201.46,31.32c-9.97,3.25-20.71,3.22-30.66-.08L151.27,4.79c-34.51-11.44-69.06,17.24-64.17,53.26l36.07,265.54c.55,4.01.58,8.08.14,12.1-45.87,416.47,152.34,475.3,489.18,475.3s535.05-58.83,489.18-475.3c-.44-4.03-.41-8.09.14-12.1l36.07-265.54Z"/>
          <g>
            <rect x="141.31" y="348.67" width="32.59" height="318" rx="16.29" ry="16.29" transform="translate(-368.76 581.88) rotate(-80.78)"/>
            <rect x="147.83" y="393.42" width="32.59" height="318" rx="16.29" ry="16.29" transform="translate(-358.87 794.46) rotate(-98.29)"/>
          </g>
          <g>
            <rect x="1048.18" y="348.67" width="32.59" height="318" rx="16.29" ry="16.29" transform="translate(1394.99 -624.41) rotate(80.78)"/>
            <rect x="1041.67" y="393.42" width="32.59" height="318" rx="16.29" ry="16.29" transform="translate(1757.11 -414.87) rotate(98.29)"/>
          </g>
          <circle cx="321.64" cy="368.5" r="100" style={{fill: '#ffffff'}}/>
          <circle cx="900.45" cy="368.5" r="100" style={{fill: '#ffffff'}}/>
        </g>
        <g id="Eyes" className={'look-'+props.look}>
          <g>
            <circle cx="321.64" cy="368.5" r="32.5"/>
            <circle cx="900.45" cy="368.5" r="32.5"/>
          </g>
        </g>
      </svg>
    </div>
  )
}