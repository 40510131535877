import { createContext, useState } from "react";

export const AccessContext = createContext(false);

const AccessProvider = ({ children }) => {
  const [accessGranted, setAccessGranted] = useState(false);

  return (
    <AccessContext.Provider value={[accessGranted, setAccessGranted]}>
      {children}
    </AccessContext.Provider>
  );
};

export default AccessProvider;