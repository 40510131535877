import { Col, Img, Row, WorkItem } from "../js/WorkContainer"
import CatFooter from "../js/CatFooter"
import video_01 from "../video/archive/6-word-story-01.mp4"
import video_02 from "../video/archive/dog-therapy-01.mp4"
import video_03 from "../video/archive/dog-therapy-02.mp4"
import video_04 from "../video/archive/dog-therapy-03.mp4"
import image_01 from "../images/work/archive/baku-01.png"
import image_02 from "../images/work/archive/baku-02.png"
import image_03 from "../images/work/archive/baku-03.png"
import image_04 from "../images/work/archive/ihangar-01.png"
import image_05 from "../images/work/archive/ihangar-02.png"
import image_06 from "../images/work/archive/ihangar-03.png"
import image_07 from "../images/work/archive/cat-01.png"
import image_08 from "../images/work/archive/cat-02.png"
import image_09 from "../images/work/archive/cat-03.png"
import image_10 from "../images/work/archive/bridge-rep-01.png"
import image_11 from "../images/work/archive/6-word-story-01.png"
import image_12 from "../images/work/archive/6-word-story-02.png"
import image_13 from "../images/work/archive/charlie-01.png"
import image_14 from "../images/work/archive/charlie-02.png"
import image_15 from "../images/work/archive/charlie-03.png"
import image_16 from "../images/work/archive/paprika-01.png"
import image_17 from "../images/work/archive/paprika-02.png"
import image_18 from "../images/work/archive/paprika-03.png"
import image_19 from "../images/work/archive/paprika-04.png"
import image_20 from "../images/work/archive/paprika-05.png"
import image_21 from "../images/work/archive/dog-therapy-01.png"
import image_22 from "../images/work/archive/dog-therapy-02.png"
import image_23 from "../images/work/archive/dog-therapy-03.png"
import image_24 from "../images/work/archive/tetris-01.png"
import image_25 from "../images/work/archive/tetris-02.png"
import image_26 from "../images/work/archive/tetris-03.png"
import image_27 from "../images/work/archive/hang-in-01.png"

export default function WorkArchive() {
  let delayCount = 1;

  return(
    <>
    <div className="work-content">
      <WorkItem title="The Baku">
        <Row>
          <Col width="34-9">
            <Img src={image_01} alt="Interactive AR (augmented-reality) exhibit featuring the Baku" />
          </Col>
          <Col width="20">
            <Img src={image_02} alt="Short throw projector used for exhibit" />
          </Col>
          <Col width="41-4">
            <Img src={image_03} alt="Raspberry Pi equipped with camera module" />
          </Col>
        </Row>
      </WorkItem>
      <Row>
        <Col width="34-9">
          <WorkItem title="iHangar" topBorder delay={delayCount++}>
            <Row>
              <Col width="53">
                <Img src={image_04} alt="Museum info brochure" border />
              </Col>
              <Col width="41-7" rowOnMobile>
                <Img src={image_05} alt="Staff badge, front with employee photo" border />
                <Img src={image_06} alt="Staff badge, back with museum logo" border />
              </Col>
            </Row>
          </WorkItem>
        </Col>
        <Col width="63-2">
          <WorkItem title="Raining Cats" topBorder delay={delayCount++}>
            <Row>
              <Col width="65-8">
                <Img src={image_07} alt="Raining Cat print, red/falling version" />
              </Col>
              <Col width="31-3">
                <Img src={image_08} alt="Raining Cat print, yellow/tangled version" />
                <Img src={image_09} alt="Raining Cat print, green/sinking version" />
              </Col>
            </Row>
          </WorkItem>
        </Col>
      </Row>
      <Row>
        <Col width="34-9">
          <WorkItem title="Bridge Rep Theater Poster" topBorder delay={delayCount++}>
            <Img src={image_10} alt="Poster for two plays performed by the Bridge Repertory Theater in Boston"/>
          </WorkItem>
        </Col>
        <Col width="63-2">
          <WorkItem title="Six Word Story" topBorder delay={delayCount++}>
            <Row>
              <Col>
                <video autoPlay loop muted poster={image_11}>
                  <source src={video_01} type="video/mp4"/>
                  Work, work, work, and some more...
                </video>
              </Col>
              <Col>
                <Img src={image_12} alt="Stools labeled with 'Work, work, work, and some more'" />
              </Col>
            </Row>
          </WorkItem>
          <WorkItem title="Je suis Charlie" topBorder delay={delayCount++}>
            <Row>
              <Col>
                <Img src={image_13} alt="Censorship-themed window art installation"/>
              </Col>
              <Col>
                <Img src={image_14} alt="Close-up of censorship-themed window art installation"/>
                <Img src={image_15} alt="Quote from Don Ho on freedom of speech in response to 'Je suis Charlie'"/>
              </Col>
            </Row>
          </WorkItem>
        </Col>
      </Row>
      <Row>
        <Col width="63-2">
          <WorkItem title="Paprika Anthology" topBorder delay={delayCount++}>
            <Col>
              <Img src={image_16} alt="Cover of Paprika anthology" />
              <Row>
                <Img src={image_17} alt="Spread of table of contents" />
                <Img src={image_18} alt="Spread of opening forward" />
              </Row>
              <Row>
                <Img src={image_19} alt="First spread of article" />
                <Img src={image_20} alt="First spread of interview" />
              </Row>
            </Col>
          </WorkItem>
        </Col>
        <Col width="34-9">
          <WorkItem title="Dog Therapy" topBorder delay={delayCount++}>
            <Col>
              <video autoPlay loop muted poster={image_21}>
                <source src={video_02} type="video/mp4"/>
                <a href="https://vimeo.com/124012724">2014 Dog Therapy Event at Mugar Library</a>
              </video>
              <video autoPlay loop muted poster={image_22}>
                <source src={video_03} type="video/mp4"/>
                <a href="https://vimeo.com/170347018">2016 Dog Therapy Event at Mugar Library</a>
              </video>
              <video autoPlay loop muted poster={image_23}>
                <source src={video_04} type="video/mp4"/>
                <a href="https://vimeo.com/127578974">2015 Dog Therapy Event at Mugar Library</a>
              </video>
            </Col>
          </WorkItem> 
        </Col>
      </Row>
      <Row>
        <Col width="63-2">
          <WorkItem title="T S Z I L 7 O" topBorder delay={delayCount++}>
            <Row>
              <Col width="40-4">
                <Img src={image_24} alt="Tetris-inspired interactive wall art installation" />
              </Col>
              <Col width="58-9">
                <Img src={image_25} alt="Close-up of Tetris-inspired magnetic puzzle pieces" />
                <Img src={image_26} alt="Bin of Tetris-inspired magnetic puzzle pieces" />
              </Col>
            </Row>
          </WorkItem>
        </Col>
        <Col width="34-9">
          <WorkItem title="OTL" topBorder delay={delayCount++}>
            <Img src={image_27} alt="Poster collage of 'The Scream' with overlaid words of encouragement" />
          </WorkItem> 
        </Col>
      </Row>
    </div>
    <CatFooter 
      delay={delayCount++}
      prevText="Kaiser"
      prevLink="/work/kp"  
      nextText="Meta"
      nextLink="/work/fb"  
      />
  </>
  )
}