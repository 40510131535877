import { Link } from "react-router-dom"
import "../css/About.css"

export default function About() {
  return (
    <>
      <div className="about-container">
        <p>
          A designer in love with programming—I&rsquo;m a designer based <Br/>in the San Francisco Bay Area with over six years of professional experience specializing in design systems, branding, and front-end development.  
        </p>
        <p>
          I&rsquo;ve had the opportunity to work with some of the largest enterprises in healthcare and tech, bringing their vision to <Br/>life through clean and creative design. My multi-disciplinary background in design and development allows me to work cross-functionally and help teams move fast while reducing friction in the design-development process.
        </p>
        <p>
          When it comes to inspiration, I find it in a variety of sources, including art, tech, and science. I am fascinated by innovation created from the intersections of these fields, and it drives me to push boundaries and challenge expectations in my work.
        </p>
        <div className="links">
          <Link to="/files/Zhang-Lucy_resume.pdf" target="_blank" download>Resume</Link>
          <span class="divider"/>
          <a href="https://linkedin.com/in/lz422" target="_blank" rel="noreferrer" >LinkedIn</a>
        </div>
      </div>
    </>
  )
}

function Br() {
  return(
    <br className="break"/>
  )
}