import { useContext, createRef, useEffect } from 'react'
import { createRoot } from 'react-dom/client'
import { createBrowserRouter, RouterProvider, useLocation, useOutlet } from 'react-router-dom'
import { CSSTransition, SwitchTransition } from 'react-transition-group'
import AccessProvider from './js/AccessProvider'
import { AccessContext} from './js/AccessProvider'
import Header from './js/Header'
import Nav from './js/Nav'
import Home from './pages/Home'
import Login from './js/Login'
import Work from './pages/Work'
import WorkFB from './pages/WorkFB'
import WorkMck from './pages/WorkMck'
import VisionCenter from './pages/mck/VisionCenter'
import WorkViz from './pages/WorkViz'
import WorkKP from './pages/WorkKP'
import WorkArchive from './pages/WorkArchive'
import About from './pages/About'
import NotFound from './pages/NotFound'
import './css/index.css'
import './css/fonts.css'

const routes = [
  { 
    path: '/', 
    name: 'Home', 
    element: <Home />, 
    nodeRef: createRef() 
  },
  { 
    path: '/work', 
    name: 'Work', 
    element: <Work />, 
    nodeRef: createRef() 
  },
  { 
    path: '/work/fb', 
    name: 'Meta Work', 
    element: <RestrictedRoute> <WorkFB /> </RestrictedRoute>,
    nodeRef: createRef() 
  },
  { 
    path: '/work/mck', 
    name: 'McKesson Work', 
    element: <WorkMck />, 
    nodeRef: createRef() 
  },
  { 
    path: '/work/mck/vision-center', 
    name: 'McKesson Virtual Vision Center', 
    element: <VisionCenter />, 
    nodeRef: createRef() 
  },
  { 
    path: '/work/viz', 
    name: 'Viz Work', 
    element: <WorkViz />, 
    nodeRef: createRef() 
  },
  { 
    path: '/work/kp', 
    name: 'Kaiser Permanente Work', 
    element: <WorkKP />, 
    nodeRef: createRef() 
  },
  { 
    path: '/work/archive', 
    name: 'Archived Work', 
    element: <WorkArchive />, 
    nodeRef: createRef() 
  },
  { 
    path: '/about', 
    name: 'About', 
    element: <About />, 
    nodeRef: createRef() 
  },
  {
    path: '*',
    name: 'Page Not Found',
    element: <NotFound />,
    nodeRef: createRef()
  }
]

const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    children: routes.map((route) => ({
      index: route.path === '/',
      path: route.path === '/' ? undefined : route.path,
      element: route.element,
    })),
  },
])

function RestrictedRoute(props){
  const [accessGranted] = useContext(AccessContext);
  
  if (accessGranted === false) {
    return <Login />;
  }

 return props.children;
};

function App() {
  const location = useLocation()
  const currentOutlet = useOutlet()
  const { name, nodeRef } =
    routes.find((route) => route.path === location.pathname) ?? {}
  
  useEffect(() => {
    document.title = "Lucy Zhang | Designer・Developer | Portfolio" + ( 
      ["Home", "Work", undefined].includes(name) ? "" : " – " + name 
    );

    window.scrollTo({ top: 0, behavior: 'smooth' });
    window.history.scrollRestoration = 'manual';
  }, [name]);

  return (
    <div className='siteContainer'>
      <Header />
      <Nav currentPage={location.pathname} />
        <div className='content'>
          <AccessProvider>
            <SwitchTransition>
              <CSSTransition
                key={location.pathname}
                nodeRef={nodeRef}
                timeout={400}
                classNames="page"
                unmountOnExit
              >
                {(state) => (
                  <div ref={nodeRef} className="page">
                    {currentOutlet}
                  </div>
                )}
              </CSSTransition>
            </SwitchTransition>
          </AccessProvider>
        </div>
    </div>
  )
}

const container = document.getElementById('root')
const root = createRoot(container)
root.render(<RouterProvider router={router} />)
