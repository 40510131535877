import { useRef, useState } from 'react'
import { Link } from "react-router-dom"
import '../css/Nav.css'

export default function Nav(props) {

  const mobileMenuCheckboxRef = useRef(null);
  
  function NavLink(props){
    return (
      <Link id={'nav-link-' + props.title} 
        to={props.href}
        onClick={resetMobileMenu}
        >
        {props.title}
        </Link>
    );
  }
  
  function resetMobileMenu() {
    mobileMenuCheckboxRef.current.checked = false;
  }

  return(
    <div className='nav-container'>
      <div className='mobile-nav'>
        <input type='checkbox' id='mobile-menu' ref={mobileMenuCheckboxRef} />
        <label htmlFor='mobile-menu' className='mobile-menu-icon'>
          <div className='icon-container'></div>
        </label>
      </div>
      <nav className='nav-links'>
        <NavLink title='work' href='/' />
        <NavLink title='about' href='/about' />
        <CurrentPageIndicator page={props.currentPage}/>
      </nav>
    </div>
  );
}

function CurrentPageIndicator(props){
  const [currentPage] = useState(0);

  const getPadding = () => {
    if(props.page.startsWith('/about')){
      return 'current-page-padding-about';
    }
    return 'current-page-padding-work';
  }
  
  return(
    <div className={'indicator-container ' + getPadding(currentPage)}>
      <div className='indicator'></div>
    </div>
  );

}
