import CatFooter from "./CatFooter"
import "../css/DocTemplate.css"

export default function DocTemplate() {
  return (
    <>
      <div className="doc-content">
        <p>
          Test page.
        </p>
      </div>
      <CatFooter 
        nextText="Home"
        nextLink="/"  
        />
    </>
  )
}