import { forwardRef } from "react"
import cx from "classnames"
import "../css/WorkContainer.css"

export function Col(props) {
  return (
    <div 
      className={cx("col", {
        "empty": props.empty,
        "justify-content-bottom": props.justifyBottom,
        "mobile-alt": props.mobileAlt,
        "row-on-mobile": props.rowOnMobile,
        [`w${props.width}`]: props.width,
      })}
      id={props.id}
      style={props.style}>
      {props.children}
    </div>
  );
}

export const Img = forwardRef((props, ref) => {
  return (
    <div 
      className={cx("image", {
        border: props.border,
        [`align-items-${props.align}`]: props.align,
        "constrain-height": props.constrainHeight,
        "constrain-mobile-height": props.constrainMobileHeight,
        "fit-mobile-height": props.fitMobileHeight,
        scrollable: props.scrollable,
        stacked: props.stacked,
      })}
      id={props.id}
      style={props.style}
      ref={ref}
      >
      <img
        src={props.src}
        alt={props.alt}
      />
    </div>
  );
});

export function Row(props) {
  return (
    <div
      className={cx("row", {
        "do-not-collapse": props.doNotCollapse,
      })}
      id={props.id}
      style={props.style}>
      {props.children}
    </div>
  );
}


export function WorkItem(props) {
  return (
    <div class="work-divider-container">
      <div
        className={cx("work-divider", "slide-in-from-bottom", {
          "top-border": props.topBorder,
          "top-border-on-mobile": props.topBorderOnMobile,
        })}
        style={{
          animationDelay: `${props.delay}00ms`
        }}
      >
        <h2>{props.title}</h2>
        {props.children}
      </div>
    </div>
  );
}
