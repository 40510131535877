import { useContext, useState } from "react"
import { AccessContext } from "./AccessProvider"
import cx from "classnames"
import "../css/Login.css"
import CatFooter from "./CatFooter"

export default function Login() {

  const [, setAccessGranted] = useContext(AccessContext);
  const accessCode = "Move fast!";

  const [errorMessage, setErrorMessage] = useState('\u00A0');
  const [preventAnimation, setPreventAnimation] = useState(false);
  const [nudge, setNudge] = useState(false);
  const [shake, setShake] = useState(false); 
  
  const handleSubmit = (event) => {
    event.preventDefault();
    setPreventAnimation(true);
    
    setNudge(true);
    resetNudge();
    
    var {pass} = document.forms[0];
    
    if (accessCode !== pass.value) {
      setShake(true);
      resetShake();
      setErrorMessage('Please try again.');
      document.forms[0].reset();
    } else {
      setAccessGranted(true);
    }
  }

  function RightArrow() {
    return(
      <svg className={cx({"nudge" : nudge})}
        xmlns="http://www.w3.org/2000/svg" 
        viewBox="0 -1 42 42" 
        >
          <title>Enter</title>
          <path vectorEffect="non-scaling-stroke" d="m25,0l15,20l-15,20" fill="none" stroke="var(--off-black)" strokeWidth="1"/>
          <line vectorEffect="non-scaling-stroke" fill="none" stroke="var(--off-black)" strokeWidth="1" x1="0" x2="40" y1="20" y2="20"/>
      </svg>
    );
  }

  function resetNudge() {
    const timeout1 = setTimeout(() => {
      setNudge(false);
    }, 300)

    return () => clearTimeout(timeout1);
  }

  function resetShake() {
    const timeout2 = setTimeout(() => {
      setShake(false);
    }, 300)

    return () => clearTimeout(timeout2);
  }

  return (
  <>
  <div className="login">
    <form className={cx("access-code-prompt", {
      "prevent-animation" : preventAnimation,
      })}
        onSubmit={handleSubmit} 
        >
        <label htmlFor="code">An access code <Tooltip/> is required to view my Meta portfolio:</label>
        <div className="access-code-input">
          <input autoFocus type="password" name="pass" />
          <button type="submit"><RightArrow/></button>
        </div>
        <div className={cx("error-message", {
          "shake" : shake,
          })}> 
            {errorMessage}
          </div>
      </form>
    </div>
    <CatFooter 
      prevText="Archive"
      prevLink="/work/archive"  
      nextText="McKesson"
      nextLink="/work/mck"  
      />
  </>
  )
}

function Tooltip() {
  return(
    <div className="tooltip-container">
      <button className="tooltip-icon" type="button">?</button>
      <div className="tooltip-message">Can be found under my Meta work experience on LinkedIn!</div>
    </div>
  )
}