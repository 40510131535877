import { Col, Img, Row, WorkItem } from "../js/WorkContainer"
import CatFooter from "../js/CatFooter"
import image_01 from "../images/work/viz/indigo-01.jpg"
import image_01m from "../images/work/viz/indigo-01m.jpg"
import image_02 from "../images/work/viz/indigo-02.jpg"
import image_02m from "../images/work/viz/indigo-02m.jpg"
import image_03 from "../images/work/viz/indigo-03.jpg"
import image_03m from "../images/work/viz/indigo-03m.jpg"
import image_04 from "../images/work/viz/indigo-04.jpg"
import image_04m from "../images/work/viz/indigo-04m.jpg"
import image_05 from "../images/work/viz/indigo-05.jpg"
import image_05m from "../images/work/viz/indigo-05m.jpg"
import image_06 from "../images/work/viz/indigo-06.jpg"
import image_06m from "../images/work/viz/indigo-06m.jpg"
import image_07 from "../images/work/viz/ghibli-01.jpg"
import image_08 from "../images/work/viz/ghibli-02.jpg"
import image_09 from "../images/work/viz/signage-01.jpg"
import image_10 from "../images/work/viz/signage-02.jpg"
import image_11 from "../images/work/viz/signage-03.jpg"
import image_12 from "../images/work/viz/signage-04.jpg"
import image_13 from "../images/work/viz/signage-05.jpg"
import image_14 from "../images/work/viz/manga-in-theory-01.jpg"
import image_15 from "../images/work/viz/manga-in-theory-02.jpg"
import image_16 from "../images/work/viz/manga-in-theory-03.jpg"
import image_17 from "../images/work/viz/manga-in-theory-04.jpg"
import image_18 from "../images/work/viz/manga-in-theory-05.jpg"

export default function WorkViz() {

  let delayCount = 1;

  return(
    <>
      <div className="work-content">
        <Row>
          <Col width="34-9">
            <WorkItem title="Indigo Sales Banners">
              <Col mobileAlt>
                <Img src={image_01m} alt="Tokyo Ghoul small banner" border/>
                <Img src={image_02m} alt="Death Note small banner"/>
                <Img src={image_03m} alt="My Hero Academia small banner"/>
                <Img src={image_04m} alt="Naruto small banner" border/>
                <Img src={image_05m} alt="Dragon Ball Super small banner" border/>
                <Img src={image_06m} alt="Jojo's Bizarre Adventure small banner"/>
              </Col>
              <Col>
                <Row>
                  <Img src={image_01} alt="Tokyo Ghoul vertical banner" border/>
                  <Img src={image_02} alt="Death Note vertical banner"/>
                </Row>
                <Row>
                  <Img src={image_03} alt="My Hero Academia vertical banner"/>
                  <Img src={image_04} alt="Naruto vertical banner" border/>
                </Row>
                <Row>
                  <Img src={image_05} alt="Dragon Ball Super vertical banner" border/>
                  <Img src={image_06} alt="Jojo's Bizarre Adventure vertical banner"/>
                </Row>
              </Col>
            </WorkItem>
          </Col>
          <Col width="63-5">
            <WorkItem title="Studio Ghibli Official Picture Books" topBorderOnMobile delay={delayCount++}>
              <Row>
                <Img src={image_07} alt="Cover of Castle in the Sky Picture Book" />
                <Img src={image_08} alt="Cover of Princess Mononoke Picture Book" />
              </Row>
            </WorkItem>
            <WorkItem title="Tradeshow & Retail Signage" topBorder delay={delayCount++}>
              <Row>
                <Col width="72">
                  <Row>
                    <Img src={image_09} alt="Boruto poster giveaway signage" border />
                    <Img src={image_10} alt="2-day panel schedule" border />
                  </Row>
                  <Row>
                    <Img src={image_11} alt="Signage for in-stock clothing sizes" border />
                  </Row>
                </Col>
                <Col width="24-6">
                  <Img src={image_12} alt="1-day panel schedule" border />
                  <Img src={image_13} alt="Pricing tags for retail items" border />
                </Col>
              </Row>
            </WorkItem>
            <WorkItem title="Manga in Theory and Practice" topBorder delay={delayCount++}>
              <Col>
                <Row>
                  <Col width="65-4">
                    <Img src={image_14} alt="Manga in Theory and Practice blog splash image" />
                    <Img src={image_15} alt="Manga in Theory and Practice horizontal banner" />
                    <Img src={image_16} alt="Manga in Theory and Practice small ad banner" />
                  </Col>
                  <Col width="30-7">
                    <Img src={image_17} alt="Manga in Theory and Practice vertical ad banner" constrainMobileHeight fitMobileHeight/>
                  </Col>
                </Row>
                <Img src={image_18} alt="Manga in Theory and Practice horizontal ad banner" />
              </Col>
            </WorkItem>
          </Col>
        </Row>
      </div>
      <CatFooter 
        delay={delayCount++}
        prevText="McKesson"
        prevLink="/work/mck"  
        nextText="Kaiser"
        nextLink="/work/kp"  
        />
    </>
  )
}