import { Link } from "react-router-dom"
import '../css/Header.css'

export default function Header() {
  return(
    <header className='myHeader'>
      <Link to="/"><h1>Lucy Zhang</h1></Link>
      <p><Link to="/">designer</Link>&ensp;|&ensp;<a href="https://github.com/http422" target="_blank" rel="external noreferrer">developer</a></p>
    </header>
  );
}