import { useEffect, useRef, useState } from "react"
import { Col, Img, Row, WorkItem } from "../js/WorkContainer"
import CatFooter from "../js/CatFooter"
import video_01 from "../video/fb/aria-01.mp4"
import image_01 from "../images/work/fb/comms-portal-01.png"
import image_02 from "../images/work/fb/comms-portal-02.png"
import image_03 from "../images/work/fb/comms-portal-03.png"
import image_04 from "../images/work/fb/portraits-of-pride-01.png"
import image_05 from "../images/work/fb/oversight-01.png"
import image_06 from "../images/work/fb/community-voices-01.png"
import image_07 from "../images/work/fb/policy-comms-wiki-01.png"
import image_08 from "../images/work/fb/google-doc-template-01.png"
import image_09 from "../images/work/fb/google-doc-template-02.png"
import image_10 from "../images/work/fb/google-doc-template-03.png"
import image_11 from "../images/work/fb/covid-01.png"
import image_12 from "../images/work/fb/covid-02.png"
import image_13 from "../images/work/fb/covid-03.png"
import image_14 from "../images/work/fb/covid-04.png"
import image_15 from "../images/work/fb/covid-05.png"
import image_16 from "../images/work/fb/covid-06.png"
import image_17 from "../images/work/fb/aria-01.png"
import image_18 from "../images/work/fb/fb-slides-01.png"
import image_19 from "../images/work/fb/fb-slides-02.png"
import image_20 from "../images/work/fb/fb-slides-03.png"
import image_21 from "../images/work/fb/data-portability-01.png"
import image_22 from "../images/work/fb/cser-01.png"

export default function WorkFB() {

  const [commsPortalHeight, setCommsPortalHeight] = useState(0);
  const [prideHeight, setPrideHeight] = useState(0);
  const commsPortalRef = useRef(null);
  const prideRef = useRef(null);

  let delayCount = 1;

  useEffect(() => {

    observeCommsPortal();
    observePride();

  }, [])

  function observeCommsPortal() {
    const observer = new ResizeObserver(entries => {
      setCommsPortalHeight(entries[0].contentRect.height);
    })
    observer.observe(commsPortalRef.current)
    return () => commsPortalRef.current && observer.unobserve(commsPortalRef.current)
  }

  function observePride() {
    const observer = new ResizeObserver(entries => {
      setPrideHeight(entries[0].contentRect.height);
    })
    observer.observe(prideRef.current)
    return () => prideRef.current && observer.unobserve(prideRef.current)
  }
  
  return (
    <>
      <div className="work-content">
        <Row>
          <Col width="73-2">
            <Row>
              <WorkItem title="Comms Portal">
                <Row>
                  <Col width="59-5">
                    <Img src={image_01} alt="Comms Portal homepage" ref={commsPortalRef}/>
                  </Col>
                  <Col width="37-9">
                    <Row doNotCollapse>
                      <Img src={image_02} alt="Comms Portal mobile page on XFN best practices" border />
                      <Img src={image_03} alt="Comms Portal mobile on headcount process" border />
                    </Row>
                  </Col>
                </Row>
              </WorkItem>
            </Row>
            <Row>
              <Col>
                <WorkItem title="Portraits of Pride" topBorder delay={delayCount++}>
                  <Img src={image_04} alt="Portraits of Pride landing page" ref={prideRef}/>
                </WorkItem>
              </Col>
              <Col>
                <WorkItem title="Oversight Board Facts" topBorder delay={delayCount++}>
                  <Img src={image_05} alt="Oversight Board Facts landing page" border />
                </WorkItem>
              </Col>
            </Row>
          </Col>
          <Col width="24-9">
            <WorkItem title="Community Voices" topBorderOnMobile delay={delayCount++}>
              <Img src={image_06} alt="Community Voices wiki page" align="top" scrollable border
                style={{
                  maxHeight: commsPortalHeight + prideHeight + 86.75,
                }}
                  />
            </WorkItem>
          </Col>
        </Row>
        <Row>
          <Col width="30-7">
            <Row>
              <WorkItem title="Policy Comms Wiki" topBorder delay={delayCount++}>
                <Img src={image_07}
                  alt="Internal wiki page featuring creative resources for Policy Comms"
                  align="top"
                  border
                  constrainMobileHeight
                  scrollable
                  />
              </WorkItem>
            </Row>
            <Row>
              <WorkItem title="Google Doc Templates" topBorder delay={delayCount++}>
                <Row>
                  <Col width="46-4">
                    <Img src={image_08}
                      alt="Google doc template, example 1"
                      align="top"
                      border
                      constrainMobileHeight
                      scrollable
                      />
                  </Col>
                  <Col width="47-6" rowOnMobile>
                    <Img src={image_09} alt="Google doc template, example 2" border />
                    <Img src={image_10} alt="Google doc template, example 3" border />
                  </Col>
                </Row>
              </WorkItem>
            </Row>
          </Col>
          <Col width="67-3">
            <Row>
              <Col width="70">
                <WorkItem title="Contact Tracing Toolkit" topBorder delay={delayCount++}>
                  <Col>
                    <Row doNotCollapse>
                      <Img src={image_11} alt="Contact Tracing Toolkit cover page" border 
                        style={{borderColor:'#504878'}}
                        />
                      <Img src={image_12} alt="Contact Tracing Toolkit overview page" border />
                      <Img src={image_13} alt="Contact Tracing Toolkit table of contents page" border />
                    </Row>
                    <Row doNotCollapse>
                      <Img src={image_14} alt="Contact Tracing Toolkit, section 01" border />
                      <Img src={image_15} alt="Contact Tracing Toolkit, section 02" border />
                      <Img src={image_16} alt="Contact Tracing Toolkit, section 03" border />
                    </Row>  
                  </Col>
                </WorkItem>
              </Col>
              <Col width="27-3">
                <WorkItem title="Aria Demo" topBorder delay={delayCount++}>
                  <video autoPlay loop muted poster={image_17} className="border grey-bg constrain-mobile-height">
                    <source src={video_01} type="video/mp4"/>
                    Aria Prototype App Demo
                  </video>
                </WorkItem>
              </Col>
            </Row>
            <Row>
              <Col width="45-3">
                <Row>
                  <WorkItem title="Cross-Company Decks" topBorder delay={delayCount++}>
                    <Col>
                      <Img src={image_18} alt="Slide on removing misinformation from Meta's family of apps" border />
                      <Img src={image_19} alt="Slide on information operations prevented worldwide" border />
                      <Img src={image_20} alt="Slide on action taken against terrorism and organized hate" border />
                    </Col>
                  </WorkItem>
                </Row>
                <Row>
                  <WorkItem title="Data Portability" topBorder delay={delayCount++}>
                    <Img src={image_21} alt="One pager on transferring personal data from Facebook" border />
                  </WorkItem>
                </Row>
              </Col>
              <Col width="51-7">
                <WorkItem title="CSER Highlights" topBorder delay={delayCount++}>
                  <Img src={image_22} 
                    alt="Infographic featuring highlights from Meta's Community Standards Enforcement Report" 
                    align="top"
                    border 
                    constrainMobileHeight
                    scrollable
                    />
                </WorkItem> 
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
      <CatFooter 
        delay={delayCount++}
        prevText="Archive"
        prevLink="/work/archive"  
        nextText="McKesson"
        nextLink="/work/mck"  
        />
    </>
  );
}
