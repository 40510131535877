import DocTemplate from "../../js/DocTemplate"

export default function VisionCenter() {
  return (
    <>
      <DocTemplate />
    </>
  )
}

// TO-DO: Make typography helper? Also used in About page.
function Br() {
  return(
    <br className="break"/>
  )
}